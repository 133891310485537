





































import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import FileUpload from '@/app/ui/components/FileUpload/index.vue'
import CloseLine from '@/app/ui/assets/close_icon_line.vue'
import controller from '@/app/ui/controllers/ManualAdjustmentBalanceController'
import {
  EnumErrorUploadBulk,
  EnumStatusUpload,
} from '@/app/infrastructures/misc/Constants/manualAdjustSaldo'
import PayrollController from '@/app/ui/controllers/PayrollController'

@Component({
  components: {
    Modal,
    Button,
    CloseLine,
    FileUpload,
  },
})
export default class ModalUploadBulky extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({ type: String, default: 'manual-adjust-balance' }) type!:
    | 'manual-adjust-balance'
    | 'manual-adjust-basic-fee'

  controller = controller
  payrollController = PayrollController
  filename = ''
  status = ''
  enumErrorUploadBulk = EnumErrorUploadBulk

  get isProcessing(): boolean {
    return this.statusUpload === EnumStatusUpload.PROCESSING
  }

  get errUploadBulky(): string {
    if (this.type === 'manual-adjust-balance') {
      return this.controller.errUploadBulky
    } else {
      return this.payrollController.errUploadBulky
    }
  }

  get isFileInvalid(): boolean {
    return this.errUploadBulky === this.enumErrorUploadBulk.INVALID_FILE
  }
  
  get statusUpload(): EnumStatusUpload {
    if (this.type === 'manual-adjust-balance') {
      return this.controller.statusUpload
    } else {
      return this.payrollController.statusUpload
    }
  }

  private onInputFileBulkUpload(file: File) {
    this.filename = file.name

    if (this.type === 'manual-adjust-balance') {
      controller.uploadBulkyNew({ file })
    } else {
      this.payrollController.uploadBulkyManualAdjustBasicFee({ file })
    }
  }
}
